body {
  background-color: #f2f1f5;
}

.text-bold {
  font-weight: 600;
}

.App {
  height: 100vh;
}

.content {
  text-align: center;
  padding: 32px 16px;
}

img.profile {
  width: 200px;
  border: 4px solid #3784e7;
  border-radius: 50%;
}

.card {
  box-shadow: 0 4px 12px #0000001a;
  background-color: #fff;
  padding: 16px;
  margin: 16px auto;
  width: 90%;
  max-width: 500px;
  border-radius: 12px;
  text-align: left;
  position: relative;
}

.card .card-content {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 2px solid #3784e7;
}


.card .card-title, 
.card .card-title .card-title-text,
.card p {
  font-size: 13px;
}

.card h4,
.card .card-title, 
.card .card-title .card-title-text {
  font-weight: 600;
}
.text-primary {
  color: #3784e7;
}

h1 {
  font-size: 32px !important;
  margin-top: 8px;
  margin-bottom: 8px;
}

p {
  margin: 0 0 4px 0;
}

a {
  color: #3784e7;
  font-size: 13px;
}

.timeline-item {
  margin-left: 20px;
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #3784e7;
  border-radius: 50%;
  left: -16px;
  top: 5px;
}

.timeline-content h4 {
  margin: 4px 0;
  font-size: 14px;
}

.timeline-content p {
  font-size: 12px;
  margin-bottom: 12px;
}

.timeline-link {
  margin-bottom: 12px;
  padding: 12px 16px;
  background-color: #3784e7;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
}

.collapse-indicator {
  cursor: pointer;
  color: #3784e7;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  right: 16px;
  top: 15px;
  transition: transform .1s linear;
}

.collapse-indicator.rotate {
  top: 10px;
  transform: rotate(180deg);
}

.row {
  display: flex;
}

.row .col {
  flex: 1;
}

.row .col.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}